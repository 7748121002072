<template>
  <div class="rounded-t-lg pl-4 h-8 bg-pink-100 flex items-center" :class="{ 'rounded-xl mt-2 px-4': inBody}">
    <div class="inline-flex fill-current bg-no-repeat w-6 mt-2">
      <svg class="inline-block text-pink-500" style="transition-duration: inherit;" width="75%" height="75%" viewBox="0 0 24 24">
        <path id="54qz0ek4la" d="M18 9.6L15.191 7 18 4.4v5.2zM13 11c0 .552-.448 1-1 1H3c-.552 0-1-.448-1-1V3c0-.551.448-1 1-1h9c.552 0 1 .449 1 1v8zm6.012-8.854c-.626-.273-1.352-.154-1.851.306l-2.161 2V3c0-1.654-1.346-3-3-3H3C1.346 0 0 1.346 0 3v8c0 1.655 1.346 3 3 3h9c1.654 0 3-1.345 3-3V9.549l2.161 1.999c.32.297.735.452 1.158.452.234 0 .469-.047.693-.145.609-.266.988-.835.988-1.484V3.63c0-.65-.379-1.218-.988-1.484z"/>
      </svg>
    </div>
    <p class="text-pink-500 text-sm">Telemedicina disponível</p>
  </div>
</template>

<script>
export default {
  props: {
    inBody: {
      required: false,
      default: false
    },
  }
}
</script>
