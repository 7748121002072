<template>
  <div class="w-full bg-white border-black border rounded-lg shadow-xl">
    <div v-if="error" class="flex justify-center items-center" :class="{ 'h-96': layout !== 'small', 'my-28': layout !== 'small', 'h-64': layout === 'small', 'my-3': layout === 'small' }">
      <div class="justify-center items-center">
        <div class="mb-4 flex justify-center items-center">
          <img class="w-10" src="../assets/alert.png">
        </div>
        <p class="text-center text-blue-700">{{ error }}</p>
      </div>
    </div>
    <div v-else>
      <Header
        :pending="pendingDetail"
        :profile="profile"
        :whitelabel="whitelabel"
        @click="toProfile"
      />

      <p v-if="error" class="text-center text-red-500 mb-2">
        {{ error }}
      </p>

      <div v-if="showAgenda" class="px-6" :class="{'border-t pt-6': showDivider}" :style="borderColor" >
        <div v-if="pendingDetail && showUnits" class="h-10 w-full bg-gray-200 rounded-md mt-1 mb-4" :class="{ 'animate-pulse': pendingDetail }" />

        <div v-if="!pendingDetail && showUnits" class="w-full mb-4 relative">
          <label class="text-gray-400 text-xs absolute ml-2 bg-white px-1">Atendimento</label>
          <select class="w-full border border-gray-400 rounded-md p-2 text-sm mt-2 text-blue-700 overflow-ellipsis bg-white" v-model="profile.unit">
            <option v-for="u in profile.units" :value="u.id" :key="u.id">{{ u.name }}</option>
          </select>
        </div>

        <p v-if="emptyList" class="text-gray-400 font-bold text-lg">
          Nenhum horário disponível neste ponto de atendimento.
        </p>

        <Agenda
          v-else
          :pending="pendingDetail || pendingAgenda"
          :date="profile.date"
          :dates="profile.dates"
          :hour="profile.hour"
          :hours="profile.hours"
          :whitelabel="whitelabel"
          :scheduleIsTelemedicine="scheduleIsTelemedicine"
          @toProfile="toProfile"
          @setDate="value => $emit('setDate', value)"
          @setHour="value => $emit('setHour', value)"
        />
      </div>

      <div v-if="showAgenda && !emptyList" class="px-6 py-2 mt-1" :class="{ 'animate-pulse': pendingDetail || pendingAgenda }" :style="pendingColor">
        <p class="text-xs">
          Clique em uma data que te atenda e marque uma consulta. Você irá receber uma confirmação por e-mail.
        </p>
      </div>

      <div class="px-4" :class="{ 'animate-pulse': pendingDetail }">
        <div v-if="pendingDetail && showButtonInCard" class="h-14 w-full bg-gray-200 rounded-xl mt-8" />

        <button v-if="!pendingDetail && showButtonInCard" class="container py-4 mt-4 rounded-xl" :style="classReserveBtn" @click="toProfile">
          <p>Agendar consulta</p>
        </button>

        <div class="text-center m-4">
          <IconBC class="w-20" :whitelabel="whitelabel" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "./Header.vue";
import IconBC from "./IconBC.vue";
import Agenda from "./Agenda.vue";

export default {
  components: {
    IconBC,
    Header,
    Agenda
  },
  props: {
    layout: {
      required: true,
    },
    profile: {
      required: true
    },
    pendingDetail: {
      required: false,
      default: false
    },
    pendingAgenda: {
      required: false,
      default: false
    },
    error: {
      required: false,
      default: false
    },
    whitelabel: {
      required: false,
    },
    target: {
      required: false,
      default: ''
    }
  },
  data() {
    return {
      selectedUnit: this.profile.unit,
    }
  },
  computed: {
    scheduleIsTelemedicine() {
      const unit = this.profile.units.find(({ id }) => id === this.profile.unit);

      return unit && unit.telemedicine;
    },
    showButtonInCard() {
      return this.layout === 'small';
    },
    showAgenda() {
      return this.layout !== 'small';
    },
    showDivider() {
      return this.layout !== 'medium';
    },
    borderColor() {
      if (this.pendingDetail || this.pendingAgenda) {
        return { 'border-color': '#e6eaef' };
      } else if (this.whitelabel) {
        return { 'border-color': this.whitelabel.colors['primary-500'] };
      }
        return { 'border-color': '#dae0e6' };
    },
    pendingColor() {
      if (this.pendingDetail || this.pendingAgenda) {
        return { 'background-color': '#e6eaef', color: '#e6eaef' };
      } else if (this.whitelabel) {
        return { 'background-color': this.whitelabel.colors['action-200'], color: this.whitelabel.colors['action-500'] };
      }
        return { 'background-color': '#e6eaef', color: '#8498ae' };
    },
    classReserveBtn() {
      if (this.whitelabel) {
        return { 'background-color': this.whitelabel.colors['action-200'], color: this.whitelabel.colors['action-500'] };
      }
      return { 'background-color': '#039be5', color: '#FFFFFF' };
    },
    showUnits() {
      return true;
    },
    emptyList() {
      return !this.pendingDetail && !this.pendingAgenda && (!this.profile.dates || this.profile.dates.length < 1);
    }
  },
  methods: {
    toProfile() {
      let url = null;

      if(this.whitelabel) {
        url = this.whitelabel.url+'/especialista/'+this.profile.slug;
      } else {
        url = this.profile.url;
      }

      if(this.target === '_blank') {
        window.open(url);
      } else {
        window.location.assign(url);
      }
    }
  }
};
</script>