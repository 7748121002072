<template>
  <div>
    <div class="flex justify-between text-sm mb-2" :class="{ 'animate-pulse': pending }">
      <div v-if="pending" class="h-4 w-5/12 bg-gray-200 rounded mt-1" />
      <p v-else class="font-bold" :style="moreDates">Próximas datas:</p>

      <div v-if="pending" class="h-4 w-4/12 bg-gray-200 rounded mt-1" />
      <p v-else class="cursor-pointer" :style="moreHours" @click="$emit('toProfile')">Mais horários</p>
    </div>
    <div class="mb-4 flex justify-between items-center">
      <div v-if="pending" class="h-5 w-8/12 bg-gray-200 rounded mt-1 animate-pulse" />
      <p v-else class="text-sm" :style="colorDate">{{ dateFormated }}</p>

      <div>
        <div class="inline-flex fill-current bg-no-repeat w-6 mt-2 cursor-pointer" @click="activeBackDate && changeDate(-1)">
          <svg class="inline-block" :class="classActiveBackDate" style="transition-duration: inherit;" :style="colorBackDate" width="100%" height="100%" viewBox="0 0 24 24">
            <path d="M10 19c-.226 0-.454-.076-.64-.232-.425-.353-.481-.984-.128-1.408l4.476-5.371-4.316-5.362c-.345-.43-.277-1.06.152-1.406.431-.346 1.06-.278 1.407.152l4.829 6c.298.371.293.901-.011 1.267l-5 6c-.199.237-.482.36-.77.36" transform="translate(-1187 -593) matrix(-1 0 0 1 1217 586) translate(6 7.5)" />
          </svg>
        </div>

        <div class="inline-flex fill-current bg-no-repeat w-6 mt-2 cursor-pointer" @click="activeNextDate && changeDate(1)">
          <svg class="inline-block" :class="classActiveNextDate" style="transition-duration: inherit;" :style="colorNextDate" width="100%" height="100%" viewBox="0 0 24 24">
            <path d="M10 19c-.226 0-.454-.076-.64-.232-.425-.353-.481-.984-.128-1.408l4.476-5.371-4.316-5.362c-.345-.43-.277-1.06.152-1.406.431-.346 1.06-.278 1.407.152l4.829 6c.298.371.293.901-.011 1.267l-5 6c-.199.237-.482.36-.77.36" transform="translate(-1242 -593) translate(1236 586) translate(6 7.5)" />
          </svg>
        </div>
      </div>
    </div>
    <div v-if="pending" class="flex flex-wrap max-h-40" :class="{ 'animate-pulse': pending }">
      <div v-for="(_, k) in 9" :key="k" class="w-1/3 px-2 my-2">
        <div class="rounded-lg h-10 bg-gray-200 rounded">
        </div>
      </div>
    </div>
    <div v-else class="flex flex-wrap overflow-scroll overflow-y-auto max-h-40 overflow-x-hidden" :class="{ 'animate-pulse': pending }">
      <div v-for="(h, k) in hours" :key="h.start" class="w-1/3 px-2 my-2">
        <div class="p-2 rounded-lg text-center cursor-pointer"
        :style="actionColor"
        :class="{'bg-blue-100': !scheduleIsTelemedicine && !whitelabel, 'bg-pink-100': scheduleIsTelemedicine && !whitelabel, 'bg-blue-500': k === hour && !whitelabel, 'bg-pink-500': (scheduleIsTelemedicine && k === hour && !whitelabel)}" @click="$emit('setHour', k)">
          <p :style="actionColor" class="text-blue-500 text-base" :class="{'text-blue-500': !scheduleIsTelemedicine && !whitelabel, 'text-pink-500': scheduleIsTelemedicine && !whitelabel, 'text-white': k === hour && !whitelabel}" >{{ h.start }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    pending: {
      required: true
    },
    scheduleIsTelemedicine: {
      required: true
    },
    date: {
      required: true
    },
    dates: {
      required: true
    },
    hour: {
      required: true
    },
    hours: {
      required: true
    },
    whitelabel: {
      required: false
    },
  },
  data() {
    return {
       actionColor: this.whitelabel ?  { 'background-color': this.whitelabel.colors['action-200'], color: this.whitelabel.colors['action-500'] } : null,
     }
  },
  computed: {
    activeBackDate() {
      return this.date > 0;
    },
    colorName() {
      if (this.whitelabel) {
        return { color: this.whitelabel.colors['primary-100'] };
      }

      return { color: '#039be5' };
    },
    colorBackDate() {
      if (this.pending || !this.activeBackDate) {
        return { color: '#e6eaef' };
      } else if (this.whitelabel) {
        return { color: this.whitelabel.colors['primary-400'] };
      }
        return ''
    },
    colorNextDate() {
      if (this.pending || !this.activeNextDate) {
        return { color: '#e6eaef' };
      } else if (this.whitelabel) {
        return { color: this.whitelabel.colors['primary-400'] };
      }
        return ''
    },
    colorDate() {
      if (this.pending || !this.activeNextDate) {
        return { color: '#e6eaef' };
      } else if (this.whitelabel) {
        return { color: this.whitelabel.colors['primary-500'] };
      }
        return '#273569'
    },
    moreHours() {

      if (this.pending) {
        return { color: '#e6eaef' };
      } else if (this.whitelabel) {
        return { color: this.whitelabel.colors['primary-400'] };
      } else if (this.scheduleIsTelemedicine) {
        return { color: '#fc5bb3' };
      }
        return { color: '#039be5' };
    },
    moreDates() {

      if (this.pending) {
        return { color: '#e6eaef' };
      } else if (this.whitelabel) {
        return { color: this.whitelabel.colors['primary-500'] };
      } else if (this.scheduleIsTelemedicine) {
        return { color: '#fc5bb3' };
      }
        return { color: '#273569' };
    },
    classActiveBackDate() {
      if (this.pending || !this.activeBackDate) {
        return 'text-gray-200';
      } else if (this.scheduleIsTelemedicine && !this.whitelabel) {
        return 'text-pink-500';
      } else if (this.whitelabel) {
        return  '';
      }

      return 'text-blue-500';
    },
    activeNextDate() {
      return this.date < this.dates.length-1;
    },
    classActiveNextDate() {
      if (this.pending || !this.activeNextDate) {
        return 'text-gray-200';
      } else if (this.scheduleIsTelemedicine) {
        return 'text-pink-500';
      }

      return 'text-blue-500';
    },
    dateFormated() {
      if (!this.dates || this.dates.length < 1) {
        return '';
      }

      const dateText = this.dates[this.date].date;
      const arrDate = dateText.split('-');

      return `${this.day(arrDate)}, ${arrDate[2]} ${this.month(arrDate[1])} ${arrDate[0]}`;
    }
  },
  methods: {
    day(val) {
      const now = new Date();
      const days = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'];

      if (now.getFullYear() === parseInt(val[0]) && now.getMonth() === (parseInt(val[1])-1)) {
        if (now.getDate() === parseInt(val[2])) {
          return 'Hoje';
        } else if (now.getDate()+1 === parseInt(val[2])) {
          return 'Amanhã';
        }
      }

      const dateObject = new Date(parseInt(val[0]),  (parseInt(val[1])-1),  parseInt(val[2]));

      return days[dateObject.getDay()];
    },
    changeDate(val) {
      const sumIndex = this.date + val;
      const index = sumIndex > -1 && sumIndex < this.dates.length ? sumIndex : 0; 

      this.$emit('setHour', false);
      this.$emit('setDate', index);
    },
    month(val) {
      const list = {
        '01': 'Janeiro',
        '02': 'Fevereiro',
        '03': 'Março',
        '04': 'Abril',
        '05': 'Maio',
        '06': 'Junho',
        '07': 'Julho',
        '08': 'Agosto',
        '09': 'Setembro',
        '10': 'Outubro',
        '11': 'Novembro',
        '12': 'Dezembro'
      };

      return list[val];
    }
  }
}
</script>