<template>
  <div class="p-2 w-80">
    <Card
      v-if="showCard"
      :pendingDetail="pendingDetail"
      :pendingAgenda="pendingAgenda"
      :layout="layout"
      :profile="profile"
      :error="error"
      :whitelabel="tenant"
      :target="target"
      @setDate="value => changeDate(value)"
      @setHour="value => changeHour(value)"
    />
    <div v-if="showButton" class="mt-4 float-right pr-2">
      <button class="container py-3 px-10 rounded-xl" :style="classReserveBtn" @click="() => showSchedule = true">
        <p class="text-sm">Agendar consulta</p>
      </button>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Card from "./components/Card.vue";

const endpoint = "https://admin.boaconsulta.com/api/v2";

export default {
  components: {
    Card
  },
  name: "App",
  props: {
    layout: {
      required: false,
      default: 'large',
    },
    buttonSchdule: {
      required: false,
      default: false,
    },
    profileSlug: {
      required: true,
    },
    agendaId: {
      required: false,
      default: undefined
    },
    url: {
      required: false,
      default: 'https://boaconsulta.com'
    },
    whitelabel: {
      required: false,
    },
    target: {
      required: false,
      default: ''
    }
  },
  data() {
    return {
      error: false,
      showSchedule: !this.buttonSchdule,

      profile: {
        id: '',
        telemedicine: false,
        name: "",
        speciality: "",
        photo: false,
        rate: 0,
        votes: "0",
        url: '',
        unit: 0,
        units: [],
        date: 0,
        dates: [],
        hour: false,
        hours: [],
      },
      tenant: null,
      pendingDetail: true,
      pendingAgenda: true,
    };
  },
  mounted() {
    this.getInfo();
    this.getWhitelabel();
  },
  computed: {
    showCard () {
      return this.layout === 'small' || this.showSchedule;
    },
    showButton () {
      return this.layout !== 'small' && this.buttonSchdule && !this.showSchedule;
    },
    classReserveBtn() {
      if (this.whitelabel && this.tenant) {
        return { 'background-color': this.tenant.colors['action-200'], color: this.tenant.colors['action-500'] };
      }
      return { 'background-color': '#039be5', color: '#FFFFFF' };
    },
  },
  watch: {
    'profile.unit': function () {
      this.getDates();
    }
  },
  methods: {
    async getInfo () {
      if (!this.profileSlug) {
        this.error = 'Profissional não configurado.';
        console.error('Profissional não configurado.');
      } else {
        try {
          const response = await axios.get(`${endpoint}/p/directories/profile/${this.profileSlug}`);
          const {
            id,
            treatment,
            name,
            type,
            photo,
            rating,
            agendas,
            specialties,
            total_reviews_v3,
            telemedicine_active: telemedicine,
          } = response.data;

          console.log('dados', response.data);

          if (type === 'premium') {
            this.profile.id = id;
            this.profile.name = treatment ? `${treatment} ${name}` : name;
            this.profile.speciality = specialties[0].base.name;
            this.profile.votes = total_reviews_v3;
            this.profile.rate = rating || 0;
            this.profile.telemedicine = telemedicine;
            this.profile.url = `${this.url}/especialista/${this.profileSlug}`;
            this.profile.slug = this.profileSlug;
            this.pendingDetail = false;

            if (photo.includes('http')) {
              this.profile.photo = photo;
            }

            if (this.layout !== 'small') {
              this.profile.units = agendas.map(a => {
                const { location: l, telemedicine_active: telemedicine } = a;
                let address = l.full_name;

                if (l.geocodable_address) {
                  address = l.geocodable_address;
                } else if (l.address && l.district && l.city && l.state) {
                  address = `${l.address} - ${l.district} - ${l.city}/${l.state}`;
                }

                const name = telemedicine
                  ? 'Teleconsulta'
                  : `Presencial - ${address}`;

                return { id: a.id, name, telemedicine };
              });

              this.profile.unit = this.profile.units[0].id;
              if (this.agendaId && this.profile.units.some(s => s.id === this.agendaId)) {
                this.profile.unit = this.agendaId;
              } else if (this.profile.units.length > 1) {
                this.profile.unit = this.profile.units.find(u => u.name !== 'Teleconsulta').id;
              }

              this.getDates();
            }
          } else {
            this.error = 'Recurso está disponivel para perfil premium. Entre em contato para mais informações';
            console.error('Recurso está disponivel para perfil premium. Entre em contato para mais informações.');
          }
        } catch (e) {
          this.error = 'Não foi possível encontrar informações do profissional. Tente novamente.';
          console.error('Error na busca de informações do profissional.', e);
        }
      }
    },
    async getDates () {
      try {
        this.pendingAgenda = true;

        const startDate = new Date();
        const endDate = new Date();

        endDate.setDate(startDate.getDate() + 30);

        const dateIni = `${startDate.getFullYear()}-${startDate.getMonth()+1}-${startDate.getDate()}`
        const dateEnd = `${endDate.getFullYear()}-${endDate.getMonth()+1}-${endDate.getDate()}`

        const { data } = await axios.get(`${endpoint}/p/slots?agenda_ids=${this.profile.unit}&start_date=${dateIni}&end_date=${dateEnd}`);
        const { date_slots: slots } = data[0];

        this.profile.dates = slots.filter(s => s.slots && s.slots.length > 0);

        this.changeDate(0);
        this.pendingDetail = false;
        this.pendingAgenda = false;
      } catch (e) {
        this.error = 'Não foi possível encontrar horários. Tente novamente.';
        console.error('Error na busca dos horários', e);
      }
    },
    changeDate(value) {
      this.profile.date = value;

      if (this.profile.dates[value] && this.profile.dates[value].slots) {
        this.profile.hours = this.profile.dates[value].slots;
      }
    },
    changeHour(value) {
      this.profile.hour = value;

      this.toSchedule(value);
    },
    toSchedule(hour) {
      if (hour !== false) {
        const { id: agendaId } = this.profile.units.find(({ id }) => id === this.profile.unit);
        const { date, slots } = this.profile.dates[this.profile.date];
        const { start, duration } = slots[hour];
        const dateTime = new Date(`${date} ${start}`);
        let domain = null ;

        if(this.whitelabel) {
          domain = this.tenant.url;
        } else {
          domain = this.url;
        }

        const url = `${domain}/agendamento?professionalId=${this.profile.id}&agendaId=${agendaId}&dateTime=${dateTime.getTime()}&duration=${duration}`;

        if(this.target === '_blank') {
          window.open(url);
        } else {
          window.location.assign(url);
        }
      }
    },
    async getWhitelabel () {
      if(this.whitelabel) {

        this.pendingAgenda = true;
        this.pendingDetail = true;

        await axios
        .get(`${process.env.VUE_APP_ENV_WHITELABEL_ADMIN_URL}/config/complete/${this.whitelabel}`,{
          headers: {
          'Authorization': 'Bearer ' + process.env.VUE_APP_ENV_WHITELABEL_ADMIN_TOKEN,
          }
        })
        .then(response => { this.tenant = response.data } )
        .catch(error => console.log(error))
        .finally(() => { this.pendingAgenda = false; this.pendingDetail = false; })

      }

    }
  }
};
</script>
<style>
  @import "./assets/tailwind.css";

  * {
    font-family: Roboto, Helvetica Neue, Helvetica, Segoe UI, Arial,sans-serif;
  }
</style>