<template>
  <div>
    <TagTelemedicine v-if="profile.telemedicine" />
    <div class="m-4">
      <div class="flex container" :class="{ 'animate-pulse': pending }">
        <div class="w-24 h-24 relative" @click="$emit('click')">
          <div class="cursor-pointer w-24 h-24 bg-gray-400 rounded-full overflow-hidden relative">
            <img itemprop="image" :src="profile.photo" :alt="profile.name" width="112px" height="112px">
          </div>
        </div>

        <div v-if="pending" class="ml-4 w-full">
          <div class="h-4 w-full bg-gray-200 rounded mt-1" />
          <div class="h-4 w-full bg-gray-200 rounded mt-2" />
          <div class="h-3 w-full bg-gray-200 rounded mt-2" />
          <div class="h-4 w-full bg-gray-200 rounded mt-2" />
          <div class="h-3 w-full bg-gray-200 rounded mt-1" />
        </div>

        <div v-else class="ml-4 w-full cursor-pointer" @click="$emit('click')">
          <p  class="text-base" :style="colorName" >{{ profile.name }}</p>
          <p  class='text-sm' :style="colorSpecialty" >{{ profile.speciality }}</p>
          <StarRating :rating="profile.rate" class="text-warning-500 mt-1" />
          <p class="text-gray-500 text-xs" v-if="profile.votes > 0">({{ profile.votes }} {{ profile.votes > 1 ? 'avaliações' : 'avaliação' }})</p>
          <p class="text-gray-500 text-xs" v-else>nenhuma avaliação</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StarRating from "./StarRating.vue";
import TagTelemedicine from './TagTelemedicine.vue';

export default {
  components: {
    StarRating,
    TagTelemedicine
  },
  props: {
    pending: {
      required: true
    },
    profile: {
      required: true
    },
    whitelabel: {
      required: false
    },
  },
  computed: {
    colorName() {
      if (this.whitelabel) {
        return { color: this.whitelabel.colors['primary-500'] };
      }

      return { color: '#039be5' };
    },
    colorSpecialty() {
      if (this.whitelabel) {
        return { color: this.whitelabel.colors['primary-400'] };
      }

      return { color: '#273569' };
    },
  }
}
</script>