<template>
  <div class="flex -ml-px">
    <div v-for="star of [0,1,2,3,4]" :key="star" @click="$emit('change', star)">
      <template v-if="rounded <= (star + 0.25)">
        <svg :width="width" :height="height" viewBox="0 0 24 24">
          <g>
            <path fill="#FFF1CC" d="M17.562 21c-.158 0-.317-.036-.463-.112l-5.1-2.664-5.097 2.664c-.338.175-.746.145-1.052-.08-.308-.223-.461-.602-.396-.977l.97-5.628-4.12-3.985c-.273-.265-.372-.663-.255-1.027.117-.362.43-.627.808-.681l5.7-.828 2.548-5.126c.338-.68 1.453-.68 1.79 0l2.549 5.126 5.7.828c.378.054.69.319.808.68.117.365.018.763-.256 1.028l-4.12 3.985.97 5.628c.066.375-.088.754-.395.978-.174.128-.381.192-.59.192" />
          </g>
        </svg>
      </template>

      <template v-if="(rounded <= (star + 0.75)) && (rounded > (star + 0.25))">
        <svg :width="width" :height="height" viewBox="0 0 24 24">
          <defs>
            <path id="path-1" d="M17.5615468,20.955 C17.4025468,20.955 17.2435468,20.918 17.0985468,20.842 L11.9995468,18.178 L6.90054683,20.842 C6.56254683,21.018 6.15454683,20.987 5.84954683,20.763 C5.54154683,20.539 5.38854683,20.16 5.45254683,19.785 L6.42454683,14.157 L2.30454683,10.172 C2.02954683,9.907 1.93154683,9.509 2.04854683,9.146 C2.16454683,8.783 2.47854683,8.519 2.85554683,8.464 L8.55654683,7.636 L11.1035468,2.51 C11.4415468,1.83 12.5575468,1.83 12.8955468,2.51 L15.4425468,7.636 L21.1435468,8.464 C21.5205468,8.519 21.8345468,8.783 21.9505468,9.146 C22.0675468,9.509 21.9695468,9.907 21.6945468,10.172 L17.5745468,14.157 L18.5465468,19.785 C18.6105468,20.16 18.4575468,20.539 18.1495468,20.763 C17.9765468,20.891 17.7695468,20.955 17.5615468,20.955 Z" />
            <path id="path-3" d="M11.9995468,18.178 L6.90054683,20.842 C6.56254683,21.018 6.15454683,20.987 5.84954683,20.763 C5.54154683,20.539 5.38854683,20.16 5.45254683,19.785 L6.42454683,14.157 L2.30454683,10.172 C2.02954683,9.907 1.93154683,9.509 2.04854683,9.146 C2.16454683,8.783 2.47854683,8.519 2.85554683,8.464 L8.55654683,7.636 L11.1035468,2.51 C11.3570468,2 11.9777449,2.00018122 11.9850119,2.00009061 C11.9898566,2.0000302 11.9947015,7.39266667 11.9995468,18.178 Z" />
          </defs>
          <g id="27)-Icon/Star-1/2" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <mask id="mask-2" fill="white">
              <use xlink:href="#path-1" />
            </mask>
            <use fill="#FFF1CC" xlink:href="#path-1" />
            <mask id="mask-4" fill="white">
              <use xlink:href="#path-3" />
            </mask>
            <use fill="#FFB800" xlink:href="#path-3" />
          </g>
        </svg>
      </template>

      <template v-if="rounded > (star + 0.75)">
        <svg :width="width" :height="height" viewBox="0 0 24 24">
          <g>
            <path fill="#FFB800" d="M17.562 21c-.158 0-.317-.036-.463-.112l-5.1-2.664-5.097 2.664c-.338.175-.746.145-1.052-.08-.308-.223-.461-.602-.396-.977l.97-5.628-4.12-3.985c-.273-.265-.372-.663-.255-1.027.117-.362.43-.627.808-.681l5.7-.828 2.548-5.126c.338-.68 1.453-.68 1.79 0l2.549 5.126 5.7.828c.378.054.69.319.808.68.117.365.018.763-.256 1.028l-4.12 3.985.97 5.628c.066.375-.088.754-.395.978-.174.128-.381.192-.59.192" />
          </g>
        </svg>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    rating: {
      type: Number,
      required: true
    },
    width: {
      type: Number,
      required: false,
      default: 18
    },
    height: {
      type: Number,
      required: false,
      default: 18
    }
  },
  computed: {
    rounded () {
      return Math.floor(this.rating * 2) / 2
    }
  }
}
</script>
